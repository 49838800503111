// getMinZIndexOfModals.js
'use strict';
import createCachedSelector from '../resource/createCachedSelector.js';

/**
 * Select minimal z-index of modals
 * @kind selector
 * @param {Immutable.Map} state - root state.
 * @param {array} modalIds - modal ids.
 * @return {Number}
 */
const getMinZIndexOfModals = createCachedSelector(
  state => state.get('modals'),
  (state, modalIds) => modalIds,
  (modals, modalIds) =>
    modals
      .filter(modal => modalIds.includes(modal.get('id')))
      .reduce(
        (accumulator, modal) => Math.min(accumulator, modal.get('zIndex')),
        Infinity
      )
)((state, modalIds) => `getMinZIndexOfModals:${modalIds?.join(',')}`);

export default getMinZIndexOfModals;
