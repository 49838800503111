// RegisterAndLoginModals.jsx
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import ModalsLoader from '../container/ModalsLoader.js';
import UniversalComponent from '../component/UniversalComponent.jsx';
import { login, register } from '../resource/modalId.js';

import FadeIn from '../style/FadeIn.js';
import FadeOut from '../style/FadeOut.js';

export class RegisterAndLoginModals extends React.PureComponent {
  state = {
    hasFadedOut: !this.props.isAnyModalOpened,
  };

  hiddenTimeoutHandle = null;

  componentDidUpdate(prevProps) {
    const { isAnyModalOpened } = this.props;
    if (isAnyModalOpened !== prevProps.isAnyModalOpened) {
      clearTimeout(this.hiddenTimeoutHandle);
      if (isAnyModalOpened) {
        this.setState({
          hasFadedOut: false,
        });
      } else {
        this.hiddenTimeoutHandle = setTimeout(() => {
          this.setState({
            hasFadedOut: true,
          });
        }, 500); // TODO: remote config
      }
    }
  }

  componentWillUnmount() {
    clearTimeout(this.hiddenTimeoutHandle);
  }

  render() {
    const { isAnyModalOpened, minZIndexOfModals } = this.props;
    const { hasFadedOut } = this.state;
    if (hasFadedOut) return null;
    return (
      <StyledRegisterAndLoginModals
        visible={isAnyModalOpened}
        zIndex={minZIndexOfModals}
      >
        <ModalsLoader ids={Object.values(login)}>
          <UniversalComponent filename="LoginModals" />
        </ModalsLoader>
        <ModalsLoader ids={Object.values(register)}>
          <UniversalComponent filename="RegisterModals" />
        </ModalsLoader>
      </StyledRegisterAndLoginModals>
    );
  }
}

RegisterAndLoginModals.propTypes = {
  isAnyModalOpened: PropTypes.bool.isRequired,
  minZIndexOfModals: PropTypes.number.isRequired,
};

RegisterAndLoginModals.defaultProps = {};

const StyledRegisterAndLoginModals = styled.div`
  /* position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: ${({ zIndex }) => zIndex};
  pointer-events: 'none';
  animation: 0.25s forwards ${({ visible }) => (visible ? FadeIn : FadeOut)}; */
`;

export default RegisterAndLoginModals;
