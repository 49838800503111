// RegisterAndLoginModals.js
import { connect } from 'react-redux';

import RegisterAndLoginModals from '../component/RegisterAndLoginModals.jsx';
import getMinZIndexOfModals from '../selector/getMinZIndexOfModals.js';
import getIsAnyModalOpened from '../selector/getIsAnyModalOpened.js';
import { login, register } from '../resource/modalId.js';

const loginModalIds = Object.values(login);
const registerModalIds = Object.values(register);
const modalIds = [...loginModalIds, ...registerModalIds];

const mapStateToProps = state => {
  return {
    isAnyModalOpened: getIsAnyModalOpened(state, modalIds, [
      'hidden',
      'reversing',
    ]),
    minZIndexOfModals: getMinZIndexOfModals(state, modalIds),
  };
};

export default connect(mapStateToProps)(RegisterAndLoginModals);
